import styled from '@emotion/styled'



// const back = 'blueviolet';

export let Button2 = styled.button`
font: roboto;
font-size: 25px;
height: fit-content;
border: #0E0B16;
box-shadow: #000000;
border-style: solid;
margin: 20px;
padding: 10px;
border-radius: 12px;
&:hover{
    box-shadow: 5px 5px;
  }
@media (min-width: 420px) {
    font-size: 30px;
}
`;


export let MusicButton = styled.button`
font: roboto;
font-size: 25px;
height: fit-content;
border: #0E0B16;
box-shadow: #000000;
border-style: solid;
padding: 10px;
border-radius: 12px;

@media (min-width: 420px) {
    font-size: 30px;
}
`;




