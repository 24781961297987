// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { getDatabase } from "firebase/database";

import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";








import {
 GoogleAuthProvider,
 getAuth,
 signInWithPopup,
 signInWithRedirect,
 signInWithEmailAndPassword,
 createUserWithEmailAndPassword,
 sendPasswordResetEmail,
 signOut,
 } from "firebase/auth";
 // for offline 
 import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
 // for offline 
 import { enableIndexedDbPersistence } from "firebase/firestore"; 


 import firebase from "firebase/compat/app" ;
 import 'firebase/compat/firestore';
 import 'firebase/compat/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBLHhx9asOpaUNZCpZL87WECWcLpd7S1TA",
  authDomain: "fir-ninja-4fab2.firebaseapp.com",
  projectId: "fir-ninja-4fab2",
  storageBucket: "fir-ninja-4fab2.appspot.com",
  messagingSenderId: "413641155227",
  appId: "1:413641155227:web:fc9141d11aff481da31c3e",
  measurementId: "G-KBRV8H3QFS",
  databaseURL: "https://us-central1.firebaseio.com" // for realtime db 
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);
 // for offline  
const firestoreDb = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});


const analytics = getAnalytics(app);

export const db = getFirestore(app);


// export const realTimeDatabase = getDatabase();

export const realTimeDatabase = firebase.database().ref();
export const firestoreDB = firebase.firestore();

 //for offline 
 enableIndexedDbPersistence(db)
 .catch((err) => {
     if (err.code === 'failed-precondition') {
         console.log("offline error: " + err);
     } else if (err.code === 'unimplemented') {
       console.log("offline error: " + err);
 
     }
 });


/************** google auth  ***************/

const auth = getAuth(app);

  //  TODO: figer out what this part does 
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try{
    //const res = await signInWithPopup(auth, googleProvider);  
    const res = await signInWithRedirect(auth, googleProvider); 
    const user = res.user; 
      // check that the user is not already in the db 
    const q = query(collection(db, "users").where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if(docs.docs.length === 0){
        // add user 
      await addDoc(collection(db, "users"),{
        uid: user.displayName,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.log(err);
    alert(err.message);
  }
};

  // sign in existing user 
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


  // register new user 
const registerWithEmailAndPassword = async (name, email, password) =>{
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err){
    console.error(err);
    alert(err.message);
  }
};

  // reset password link 
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("password reset link has been sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

  // log out 
const logout = () =>{
  signOut(auth);
};

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};


