import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {Button2} from './styles/Button';
import sound from './sound/mixkit-attention-bell-ding-586.wav';
import { css } from '@emotion/css';
import { Background } from './styles/Background';
import { useSelector } from "react-redux";


const Timer = () => {
   const { id } = useParams();
   // TODO: if id == null {go back to new work sesh}
        // same for break timer 
    const history = useHistory();
    if(Number.isNaN(id)){
        history.push("/work");
    }

        // how long the timer should run in seconts 
   const [time, setTime] = useState(id * 60);
   
   const [pauseText, setPauseText] = useState("Pause Timer");
   const [paused, setPaused] = useState(false); 
   const abs_start = new Date().getTime();
   const [countDownTime, setCountDownTime] = useState(new Date().getTime() + time * 1000);
   const [startPauseTime, setStartPauseTime] = useState(0);
   const [audio, setAudio] = useState(new Audio(sound)); // audio = new Audio(sound);
   audio.muted = true;
   audio.loop = true;
   audio.play();  // so the sound will play in bachground tab 
   const color = useSelector((state) => state.color.value);

    const displayTime = ()=>{
        let min = Math.floor(time / 60);
        let sec = time % 60; 
        if(sec < 10 && sec >= 0){
            return `${min}:0${sec}`;
        }
        return `${min}:${sec}`; 
    }

    let timePaused = 0; 
    let interval = null; 
    interval = setInterval(()=>{
        let now = new Date().getTime();
            // time to go untill timer will stop 
        let distance = countDownTime - now;
        let seconds = Math.floor((distance) / 1000);
        if(!paused){
            setTime(seconds);
            console.log(time);
        }
        clearInterval(interval);
    }, 1000 );

    if(time <= 0){
        audio.muted = false;
        audio.play();
        // history.push(`/goodWork/${id}`);
    }
    
    const [wantEndCard, setWantEndCard] = useState(<div> </div>);

    const wantToEndTimer = () => {
        setWantEndCard(<div className={css`
            font-size: 20px;
            padding: 3%;
            background-color: ${color[1]};
            border: #000000;
            width: max-content;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: rotateX(0deg) translate(-50%, -50%);
            border-radius: 10%;
            border-style: solid;
        `}> 
            Do you want to end? 
            <br />  
            <Button2 className={css`
                background-color: ${color[0]}; 
            `} onClick={endTimer}> End Timer</Button2>
            <br />
            <Button2 className={css`
                    background-color: ${color[0]}; 
            `} onClick={dontEndTimer}> Don't End Timer</Button2>
        </div>) 
    }

    const endTimer = () => {
        const  now = new Date().getTime();
        //TODO: bug, if now is much later because timer is poused 
        let distance;
        // if(!pause){
        //     distance = (countDownTime - now);
        // }else{
        //     distance = countDownTime - (now + timePaused); 
        // }
        distance = (now - abs_start);
        const seconds = Math.floor((distance) / 1000);
        const timeDone = ( id * 60 - seconds) / 60;
        audio.loop = false;
        audio.pause();
        history.push(`/goodWork/${timeDone}`);
    } 
    const dontEndTimer = () => {
        setWantEndCard(<div> </div>)
    } 
   

const pause = () => {
    if(!paused){
        setStartPauseTime(new Date().getTime());
        console.log(startPauseTime)
        setPaused(true);
        setPauseText("Start Timer");
        console.log(paused);
    }
    if(paused){
        const unPause = new Date().getTime();
            //TODO: bug is still their (time still recorded when paused and then ended)
        
        timePaused = unPause - startPauseTime; 
        
        console.log(startPauseTime / 1000);
        console.log(unPause / 1000);
        console.log(timePaused / 1000);
        setCountDownTime(countDownTime + timePaused);
        setPaused(false);
        setPauseText("Pause Timer");
        console.log(paused);
    }
   }
   
    document.title =  displayTime() + " Productivity Pangolin";

    // fix the reloading problem 
    window.onbeforeunload = function(e) {
        alert("The timer will be reset if you reload the page");
        return "Do you want to exit this page? \n your timer will be reset";
      };
    
    return ( 
        <Background colorL={color[1]} colorR={color[2]} className={css`
        color: ${color[4]};
        `}>
            <h3 className={css`
                font-size: 40px;
                padding-top: 10%;
                color: ${color[4]};
            `}>Work Time!</h3>
            {displayTime()}
            <br/>
            {wantEndCard}
            <Button2 className={css`
                background-color: ${color[0]};  
                color: ${color[4]};
            `}  onClick={pause}>
                {pauseText}
            </Button2>
            <Button2 className={css`
                background-color: ${color[0]}; 
                color: ${color[4]};
            `} onClick={wantToEndTimer}> End Timer</Button2>
            

        </Background>
     );
}
 
export default Timer;